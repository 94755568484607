@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body{
    background: #F5F5FA !important;
    font-family: 'Inter', sans-serif;
}
.cnt-cntr{
    position: relative;
}
.cnt-cntr-details{
    position: relative;
    background-color: white;
}
.fixe-menu{
    position: fixed;
    top: 0;
    left: 322px;
    width: calc(100% - 322px);
    background: #FFFFFF; 
    z-index: 1;
}

.fixe-menu .css-1sgdipk{
    margin: 0;
    background: #F5F5FA !important;
    border-radius: 8px;
}
.fixe-menu header{
    background: unset;
}
.fixe-menu-detail header{
    background: unset;
}


.fixe-menu .css-1sgdipk svg path{
    fill: rgba(100, 116, 139, 1);
}
.fixe-menu .css-1sgdipk svg path{
    fill: rgba(100, 116, 139, 1);
}

/* .css-6qpffq-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    overflow: initial !important;
    white-space: nowrap;
}
.lead-table .MuiDataGrid-main div:nth-child(2) {
    overflow: scroll;
} */

.fixe-menu .MuiInputBase-input{ 
    font-weight: 500;
    font-size: 18px;
    line-height: 22px; 
    color: #CACEDC;
        width: 314px !important;
}
.fixe-menu .MuiToolbar-regular {
    min-height: 100px;
}
.breadcrumbs ul {
    list-style: none;
    display: block;
    margin: 0 0 15px;
    padding: 0;
}
.breadcrumbs ul li { 
    display: inline-block;
    margin-right: 10px; 
    color: var(--gray-01, #4D515A);
font-size: 14px;
font-family: Inter;
font-weight: 500;
line-height: 22px;
}
.breadcrumbs ul li svg { 
    position: relative;
    top: 8px;
}


.not-cntr svg path{
    fill: #64748B;
}

.not-cntr button{
    background: #F1F5F9;
border-radius: 50px;
width: 44px;
height: 44px;
margin-left: 16px;
}
.not-cntr button.user-cntr {
    background: unset;
    border-radius: 0;
    width: unset;
    height: unset;
    margin-left: 16px;
    border: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px; 
    color: #40444D;
    position: relative;
    padding-left: 15px;
    display: flex !important;
    align-items: center!important;
}
.not-cntr button.user-cntr::after {
    content: ""; 
    position: absolute;
    left: 0;
    top: 10px;
    width: 1px;
    height: 100%; 
background: #E2E8F0;
}
.not-cntr button.user-cntr img {
    position: relative;
    top: 12px;
    margin-right: 10px;
}
.not-cntr button .MuiBadge-badge{
font-size: 0;
    width: 5px;
    height: 5px;
    padding: 0;
    min-width: unset;
    top: 4px;
    right: 4px;
}

.left-menu .MuiPaper-root-MuiDrawer-paper{

border: 0;
}
.left-menu-cntr{
    padding: 30px;
    height: calc(100% - 20px);
}
.left-menu-cntr h3{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px; 
    text-transform: uppercase; 
    color: #7B8190;
    text-align: left;
    margin-bottom: 5px;
}
.left-menu-cntr ul li .MuiListItemIcon-root{
    min-width: 35px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px; 
    color: #3B424A;
}

.left-menu-cntr ul li.active-menu{
    background: #0053A0;
    border-radius: 9px;
}

.left-menu-cntr ul li.active-menu p{
    color: #fff;

}

.left-menu-cntr ul li.active-menu svg path{
    stroke: rgba(250, 171, 81, 1) !important; 

}
.left-menu-cntr .nav-list{
    text-decoration: none;
    color: black;
    padding-left: 32px;
    line-height: 15px; 
    display: block;
    text-align: left;
}
.left-menu-cntr .nav-list-cntr .drop-cntr {
    background: #0053A0;
    border-radius: 9px;
    padding-top: 15px;
    top: -15px;
    overflow: auto;
    height: 167px;

}
.left-menu-cntr .nav-list-cntr .drop-cntr .nav-list{
    padding: 0 !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px; 
    color: #F3F8FB !important;
}

.left-menu-cntr .nav-list-cntr p{
    color: #3B424A;
font-size: 18px; 
font-weight: 500;
line-height: 22px;
}

.left-menu-cntr .nav-list-cntr .drop-cntr p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}



.left-menu hr{
    width: 100%;
    height: 1px;
    opacity: .3;
    background-color: #E3E3E3;
    margin-top: 50px;
}
.nav-list-cntr{
    padding: 5px 0;
}
.set-menu{
    padding: 0;
    list-style: none;
}
.set-menu li a{
   display: block;
   text-align: left;
   color: #3B424A;
font-size: 18px; 
font-weight: 500;
line-height: 22px;
}
.set-menu li a svg{
    position: relative;
    top: 6px;
}

.page-cntr {
    margin: 100px 0 0 322px;
     
    padding: 25px;
}
.detail-page-cntr{
    margin: 10px 0 0 0;
    padding: 25px;
    width: 100%;
    position: absolute;
    height: 100vh;
    z-index: 9999;

}

.breadcrumbs li p{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px; 
    color: #4D515A;
}

.breadcrumbs li a{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px; 
    color: #4D515A;
}

.dsh-se-title h1{
    font-weight: 700;
    font-size: 32px;
    line-height: 40px; 
    color: #1E293B;
    text-align: left;
}
.dsh-se-title-filt{
    float: right;
}

.dsh-se-title-filt .MuiInputBase-formControl{
    background: #FFFFFF; 
    border: 1px solid #E2E8F0;
    box-shadow: 2px 4px 10px rgba(180, 191, 205, 0.2);
    border-radius: 4px;
}
.dsh-se-title-filt a {
    background: #FAAB53;
    border-radius: 9px;
    padding: 10px 18px 10px 36px;
    display: inline-block;
    position: relative; 
    font-weight: 500;
    font-size: 16px;
    line-height: 30px; 
    color: #FFFFFF;
}
.dsh-se-title-filt a svg{ 
    position: absolute;
    left: 7px;
    top: 14px;
}
.dsh-se-title-filt fieldset{
    border: 0;
}
.dsh-se-title-filt .MuiSelect-select{
    padding: 14px !important;
    text-align: left !important; 
}
.dsh-se-title-filt .MuiFormControl-root{
    margin: 0;
}
.dsh-se-title-filt label{
    font-weight: 500;
    font-size: 20px;
    line-height: 53px; 
    color: #4D515A;
}
.dash-led-cntr{
    padding: 10px;
}
.dash-led-cntr-ttl{
    display: flex!important;
    justify-content: space-between!important;
}

.dash-led-cntr-ttl h5{ 
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; 
    color: #4E5564;
    margin: 0;
}

.dash-led-cntr-ttl span{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px; 
    color: #10B981;
}
.dash-led-cntr h3{
    font-weight: 500;
    font-size: 30px;
    line-height: 36px; 
    color: #40444D;
    text-align: left;
    margin: 15px 0 15px;
}
.dash-led-cntr-act{
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
}
.dash-led-cntr-act a{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; 
    color: #0053A0;
}

.dash-led-cntr-act svg{
    background: #EBF1FC;
    border-radius: 4px;
    padding: 15px;
    width: 52px;
    height: 52px;
}
.dash-led-cntr-act.svg-clr2 svg{
    background: #E7FDF3; 
}
.dash-led-cntr-act.svg-clr3 svg{
    background: #FDE7E7;
}
.dash-led-cntr-act.svg-clr4 svg{
    background: #F9E7FD;
}

.dash-led-cntr-ttl span.minus-value{ 
    color: #FF5555;
}
.dash-led-cntr-ttl span.zero-value{ 
    color: #64748B;
}
.chart-cntr{
    margin-top: 25px;
}
.lead-cntr{
 
    position: relative;
}
.lead-table .MuiCheckbox-root svg{
    color: rgba(224, 231, 255, 1);
}

.lead-table .Mui-checked svg{
    color:rgba(93, 95, 239, 1);
}

.lead-table .MuiDataGrid-row{
    background: #FFF;
    min-height: 70px !important;
}
/* .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(9) {
    display: none;
} */

.lead-table .MuiDataGrid-row .MuiDataGrid-cell{ 
    min-height: 70px !important;
    border-color: #EAEAEA;
}

.MuiDataGrid-virtualScrollerRenderZone {
    overflow: scroll;
    width: 100%;
}


.lead-table p {
    color: #4D4D4D !important;
    font-size: 14px;
    font-family: Inter;
    line-height: 130%;
}

.lead-table button {
    color: #4D4D4D;
    font-size: 14px;
    font-family: Inter;
    line-height: 130%;
    border: 0;

}

.lead-table .MuiFormControl-root input{
    color: #4D4D4D !important;
    font-size: 14px;
    font-family: Inter;
    line-height: 130%;
    padding: 0; 
    width: 195px;
}

.lead-table .MuiInputAdornment-root{
    margin: 0;
}

.lead-table .MuiInputBase-root-MuiFilledInput-root:after{
    display: none;
}

.lead-table .MuiFilledInput-root {
    border: 0 !important;
    box-shadow: none !important;
    background: unset !important;
}

 

.lead-table .MuiFormControl-root-MuiTextField-root .MuiFilledInput-root:before {
    border-bottom: 0px solid !important;
}

.lead-table .MuiDataGrid-toolbarContainer {
    position: absolute;
    top: -70px;
    right: 0;
}
 

.lead-table .MuiDataGrid-toolbarContainer button{
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 52px;
    width: unset;
    display: inline-flex;
}
button.lead-submenu-btn {
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 52px;
    width: unset;
    display: inline-flex;
    position: absolute;
    right: 100px;
    top: 110px;
}



.lead-table .MuiDataGrid-toolbarContainer button span{
    margin: 0;
}

.lead-table .MuiDataGrid-columnHeadersInner{
    border-top: 0.5px solid #C4C4C4;
    border-bottom: 0.5px solid #C4C4C4;
    background: #F3F3FF;
    color: #4D4D4D;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 130%;
}


.lead-table .MuiDataGrid-columnHeaderTitle{
    color: #4D4D4D;
    font-size: 14px; 
    font-weight: 600;
    line-height: 130%;
}
.lead-table .MuiDataGrid-main{
    overflow: unset;
}
.lead-table .MuiDataGrid-main div:nth-child(2) {
    overflow: unset !important
  } 
.lead-table .MuiGrid-root {
    margin-left: 0;
}

.lead-table .MuiOutlinedInput-notchedOutline{
    border: 0px !important;
}

.lead-table .MuiDataGrid-virtualScroller{
    overflow: unset !important
}
.lead-table .MuiDataGrid-virtualScrollerRenderZone{
    position: relative;
}

.lead-table .MuiDataGrid-virtualScrollerContent {
    height: auto !important;
    width: 100% !important;
}


.home-lead-chart {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
    margin-top: 50px;
}
.home-lead-chart-big {
    width: 65%;
    background: rgb(231, 253, 243);
    padding: 30px 30px;
    border-radius: 10px;
}

.home-lead-chart-right {
    width: 33%;
    display: flex;
    justify-content: space-between;
}
.home-lead-chart-right-item {
    width: 100%;
}
.home-lead-chart-right-item.block-01{
    background: rgb(233, 231, 253);
    padding: 30px 30px;
    border-radius: 10px;
    margin-top: 30px;
}
.home-lead-chart-full-item {
    width: 100%;
    background: #fdf7e5;
    padding: 30px 30px;
    border-radius: 10px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.home-lead-chart-full-item canvas {
    height: 400px !important;
    display: flex;
    justify-content: center;
    width: 100%;
}


.home-lead-chart-right-item.block-02{
    background: rgb(233, 231, 253);
    padding: 30px 30px;
    border-radius: 10px;
}

.graph-cntr{
    padding: 50px 0;
}

.graph-cntr img{
    height: 550px;
    width: 100%;
    object-fit: cover;
}
#primary-search-account-menu .MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    top: 100px!important;
    border-radius: 14px;
    background: #FDFDFD;
    box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.19);
    width: 356px;
    border: 0;
}

#primary-search-account-menu .MuiPaper-root-MuiMenu-paper-MuiPopover-paper ul li svg { 
    height: 18px;
    width: 18px;
    margin-right: 5px;
    position: relative;
    top: 4px;
}

#primary-search-account-menu .MuiPaper-root-MuiMenu-paper-MuiPopover-paper ul li {
    color: #3C3C3C;
    font-size: 18px !important;
    font-family: Inter;
    font-weight: 300 !important;
    line-height: 35px !important;
    border-radius: 7px;
    text-align: left !important;
}
.css-6qpffq-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    overflow:initial !important;
    white-space: nowrap;
}
 
.auth-drop   { 
    width: 325px;
   padding: 15px !important;
     
}

.auth-drop button  {  
    background: unset;
    border-radius: 0; 
    border: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #40444D;
    align-items: center!important;
    display: flex!important;
    padding: 10px 25px;
}

.auth-drop button img {  
    margin-right: 5px;
}

.auth-drop hr {  
    opacity: .3;
}

 
.lead-page-cntr .create-btn2 {
    background: #FAAB53;
    border-radius: 9px;
    padding: 10px 18px 10px 18px;
    display: inline-block;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    border: 0;
}

.sec-hd h4{
    color: #1E293B;
font-size: 32px;
font-family: Inter;
font-weight: 700;
line-height: 50px;
    margin: 0;
}

.sec-hd h4 span{
    color: rgba(8, 40, 92, 0.6);
font-size: 22px; 
}
.lead-page-cntr .title-sec .MuiCard-root {
    background: unset;
    border: 0;
    box-shadow: unset;
    position: relative;
    overflow: visible !important;
}
.pop-det-cntr {
    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 14px;
}
.MuiInputBase-root.btn-rds {
    border-radius: 50px;
    margin-left: 12px;
}


button.dlt-icon {
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 52px;
    width: unset;
    position: absolute;
    right: 365px;
    display: inline-flex;
    bottom: 1px;
}

button.export-icon {
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 52px;
    width: unset;
    position: absolute;
    right: 302px;
    display: inline-flex;
    bottom: 1px;
}

.dlt-icon-dash {
    border-radius: 9px !important;
    border: 1px solid var(--stroke, #E2E8F0) !important;
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px !important;
    min-width: 52px;
    width: unset;
    display: inline-flex;
    position: absolute !important;
    top: -14px;
    right: 70px;
}
.dlt-icon-dash.dash-dlt-btn{
    right: 0px;
}


.dlt-icon-dash.followup {
    top: 67px;
}


.lead-page-cntr .MuiDataGrid-toolbarContainer {
    right: 130px;
    top: -85px;
}

.pop-det-cntr .status-cntr fieldset{
    border: 0;
}
.pop-det-cntr .MuiGrid-root>.MuiGrid-item{
    padding-left: 0 !important;
}
.pop-det-cntr .MuiGrid-root>.MuiGrid-item{
    padding-left: 0 !important;
}
.pop-det-text {
    align-items: center!important;
    display: flex!important;
    padding: 13px 0;
    border-bottom: 1px solid #ededed;

}
.pop-det-cntr h2{
    
    color: rgba(133, 133, 133, 1);
    font-size: 18px !important; 
    font-weight: 300 !important;
    line-height: 130%;

}

.pop-det-text h2 {
    display: flex;
    align-items: center;
    width: 40%;
}
.pop-det-cntr p{
    color: #333  !important;
    font-size: 15px !important;  
    line-height: 130%;
    font-weight: 500;
    word-break: break-all;
    width: 50%;
}
 


.pop-det-cntr .MuiOutlinedInput-notchedOutline{
    border: 0;
}
 
.pop-det-cntr .MuiFilledInput-root{
    border: 0 !important;
    box-shadow: none !important;
}

 .pop-det-cntr .MuiFormControl-root input {
    padding: 0 10px;
    color: rgba(133, 133, 133, 1);
    font-size: 18px !important;
    line-height: 130%;
    font-weight: 300;
    width: 122px;
    margin-right: 0;
    padding-right: 0;
}
.pop-det-text .assets-btn button{
    padding: 0;
    color: #333 !important;
    font-size: 18px !important;
    line-height: 130%;
    font-weight: 500;
    word-break: break-all;
}
 

.pop-note input{
font-size: 18px;
font-family: Inter;
font-weight: 300;

line-height: 130%;
}
.pop-note{
    margin-top: 20px;
}
#notes-add-btn { 
    background: #FAAB51;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    padding: 5px 15px;
    font-size: 16px;
    margin-left: 4px;
}
 

.pop-note .MuiDataGrid-virtualScroller{
    background: #fff;
}
.pop-note .MuiDataGrid-main.MuiDataGrid-main{
    background: #EDEDED;
}
.right-form{
    padding: 0 25px;
}
.right-form label{
    color: #323232;
font-size: 18px;
font-family: Inter;
font-weight: 300;
line-height: 130%;
}

.form-title{
    color: #3B424A;
    font-size: 32px;
    font-family: Inter;
    font-weight: 600;
    line-height: 130%;
    border-bottom: 1px solid #F3F3F3;
}

.right-form .main-btn2{
    border-radius: 10px;
    border: 1px solid #FAAB51;
    background: #FAAB51;
    color: #FFF;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 130%;
    padding: 13px 55px;
    display: block;
    margin: 0 auto;
}
.sec-hd{
    margin: 0;
}

.lead-table .MuiTablePagination-displayedRows{
    color: #64748B !important;
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
}
.lead-table .MuiTablePagination-actions button{
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    padding: 14px 12px 14px 12px;
    margin: 0 7px;
    display: inline-flex;
    width: unset;
}

.lead-table .MuiTablePagination-actions button.Mui-disabled{
    border-radius: 9px;
    border: 1px solid #E2E8F0;
    background: #F1F2F4;
    padding: 14px 12px 14px 12px;
}

.lead-table .MuiTablePagination-root{
    margin:  15px 0;
}


.recy-page-cntr .MuiPaper-root-MuiCard-root{
    background: unset;
    border: 0;
    box-shadow: unset;
}

.recy-page-cntr .MuiDataGrid-toolbarContainer {
    right: 0px;
    top: -85px;
}
.recy-page-cntr .title-sec .MuiCard-root {
    background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
    background: initial;
    border: 0;
    box-shadow: none;
}


.recy-btn{
    border-radius: 50px;
    background: #FFECEC;
    color: #E11D1D;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 130%;
    padding: 10px 35px;
}
.status-cntr fieldset{ 
    border: 0;
}
.status-cntr .MuiSelect-select.MuiSelect-outlined {
    border-radius: 50px;
    text-align: center;
    font-family: Inter;
    font-weight: 500;
    line-height: 130%;
    overflow: inherit;
    border: 8px;
    width: auto;
    padding: 8px 12px !important;
    font-size: 12px;
}
.status-cntr .MuiOutlinedInput-root .MuiSelect-icon{
    right: 0;
    display: none;
}

.MuiMenuItem-root {
    
    font-size: 16px !important; 
    font-weight: 500 !important;
    line-height: 28px !important;
    margin: 5px 0 !important;
    text-align: center !important;
    display: block !important;
   
}
.MuiMenuItem-root[data-value="New Enquiry"]{
    color: #FFFFFF;
    border-radius: 50px;
    background: #4361EE !important;
    }

.MuiMenuItem-root[data-value="Verified Lead"]{
     color: #FFFFFF;
    border-radius: 50px;
    background: #CBB2FE !important;
}
.MuiMenuItem-root[data-value="Under Discussion"]{
    color: #EC1696;
    border-radius: 50px;
    background: #F8DBEC !important;
    }

 
.MuiMenuItem-root[data-value="Negotiation"]{
    color: #FFFFFF;
    border-radius: 50px;
    background: #023047 !important;
    }

.MuiMenuItem-root[data-value="Confirmed"]{
    color: #FFFFFF;
    border-radius: 50px;
    background: #0EBD54 !important;
}

.MuiMenuItem-root[data-value="Invalid"]{
    color: #FFFFFF;
    border-radius: 50px;
    background: #FFBC42 !important;
}
.MuiMenuItem-root[data-value="Future"]{
    color: #FFFFFF;
    border-radius: 50px;
    background: #00B4D8 !important;
}

.MuiMenuItem-root[data-value="Lost"]{
    color: #FFFFFF;
    border-radius: 50px;
    background: #EE6055 !important;
}

 





.MuiPopover-paper{
    border-radius: 10px !important;
    border: 1px solid #EBEBEB !important;
    background: #FFF !important;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.09) !important;
    padding: 10px 15px;
}

.assign-cntr-label{
    color: #AEAEAE;
font-size: 15px;
font-family: Inter;
font-style: italic;
line-height: 22px;
display: block;
text-align: center;
}
.pop-det-text .status-cntr .MuiSelect-select.MuiSelect-outlined {
    border-radius: 0;
    background: unset;
    width: unset;
    text-align: left;
    color: #333 !important;
    font-size: 16px !important;
    line-height: 130%;
    font-weight: 500;
    word-break: break-all;
}
.brand-logo img{
    width: 100%;
}

.edit-nav-cntr{
    align-items: center!important;
    display: flex!important;
    margin: 10px 0;
    font-size: 16px; 
    font-weight: 300;
    line-height: 22px;
    color: #2F4364;
    cursor: pointer;
}
.edit-nav-cntr.delete-sec{ 
    color: #FF2727;
}



.edit-nav-cntr svg{
     width: 20px;
     height: 20px;
}

.assign-cntr  {
    width: 320px; 
}

.assign-cntr button{
    text-align: left;
    display: block;
    color: #636363;
    font-size: 16px;
    font-family: Inter;
    font-weight: 300;
    line-height: 22px;
    padding: 0;
    margin: 10px 0;
    background: unset !important;
}

.assign-cntr p{
    color: #8E8E8E;
    font-size: 16px;
    font-family: Inter;
    font-weight: 300;
    line-height: 22px;
}

.left-menu-cntr .MuiBox-root.css-4nuj1i{
    height: unset;
}
.assets-btn{
    background-color: unset;
}

.assets-btn .MuiBadge-standard { 
    background-color: unset;
    color: #4D515A;
    font-size: 14px; 
    font-weight: 500;
    line-height: 130%;
    border-radius: 32px;
    border: 1.5px solid #4D515A;
    width: 32px;
height: 32px;
}

.assets-btn2{
    border: 0;
}
.pop-det-text .assets-btn  {
    border: 0; 
}
.recy-status{
    border-radius: 50px;
    background: #EAFCE8;
    width: 180px;
    text-align: center;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 130%;
    padding: 10px 20px;
}


.new-lead-cntr .MuiDataGrid-toolbarContainer {
    right: 0;
    top: -85px;
}

.assign-frm{
    position: relative;
    padding-right: 25px;
    color: #4D4D4D !important;
    font-size: 18px;
    font-family: Inter;
    line-height: 130%;
}
.assign-frm svg{
    right: 0;
}

.assign-frm .MuiSelect-select{
    padding: 10px 0 !important;
    color: #4D4D4D !important;
    font-size: 18px;
    font-family: Inter;
    line-height: 130%;
}
.assign-frm .MuiInputBase-formControl{
    position: unset;
}

#primary-search-account-menu li{
    text-align: left !important;
}

#primary-search-account-menu li svg{
    position: relative;
    top: 4px;
}

.recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
    display: none;
}


.follo-up-cntr .sec-hd {
    margin: 0 0 15px;
}
.follo-up-cntr .follw-dates{
    margin-right: 15px;
}
.follo-up-cntr .MuiDataGrid-toolbarContainer { 
    top: -76px;
    right: 0;
}

.closed-page-cntr .title-sec .MuiCard-root {
    background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
    background: initial;
    border: 0;
    box-shadow: none;
}
.closed-page-cntr .MuiDataGrid-toolbarContainer {
    position: absolute;
    top: -85px;
    right: 0;
}


.closed-page-cntr .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7) {
    display: none;
}
.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    display: none;
}

/* div#mui-component-select-status{
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
} */

.MuiDataGrid-columnHeadersInner{
    overflow: hidden;
}
.MuiDataGrid-virtualScrollerRenderZone {
    overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.campaign-box.MuiBox-root .MuiDataGrid-virtualScrollerRenderZone {
    position: relative;
    overflow: scroll;
}

.campaign-box.MuiBox-root .MuiDataGrid-columnHeadersInner {
    overflow: scroll;
    transform: translate3d(0px, 0px, 0px) !important;
}

.pop-det-cntr.source .pop-det-text {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    gap: 8px;
}
.pop-det-cntr.source .pop-det-text p {
    width: 100% !important;
    padding: 0 !important;
}
.pop-det-cntr.source .pop-det-text h2 {
    width: 100% !important;
    padding: 0 !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.filter-btn-pop {
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 52px;
    width: unset;
    position: absolute;
    right: 176px;
    display: inline-flex;
    bottom: 1px;
}
.reports-table {
    overflow: scroll;
    border-collapse: collapse;
    height: 80vh;
}
.reports-table table{
    overflow: scroll;
  border-collapse: collapse;
}
.reports-table thead.MuiTableHead-root tr th{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}



@media (max-width: 1700px) {

    /** lead page ***/
    /* .lead-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 230px !important;
        width: 230px !important;
        max-width: 230px !important;
    }

    .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 230px !important;
        width: 230px !important;
        max-width: 230px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    } */


     /** recy page ***/


    
    /* .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 265px !important;
        width: 265px !important;
        max-width: 265px !important;
    }


    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 265px !important;
        width: 265px !important;
        max-width: 265px !important;
    } */


 /** closed page ***/

    /* .closed-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 290px !important;
        width: 290px !important;
        max-width: 290px !important;
    }
    
    .closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 290px !important;
        width: 290px !important;
        max-width: 290px !important;
    } */

 


}





@media (max-width: 1600px) {

    /** lead page ***/
    /* .lead-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
 .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 155px !important;
        width: 155px !important;
        max-width: 155px !important;
    }
  


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
       .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 155px !important;
        width: 155px !important;
        max-width: 155px !important;
    } */

 /** recy page ***/
    /* .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 250px !important;
        width: 250px !important;
        max-width: 250px !important; 
    }

    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 250px !important;
        width: 250px !important;
        max-width: 250px !important;
    } */


    
 /** closed page ***/

 /* .closed-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 270px !important;
    width: 270px !important;
    max-width: 270px !important;
}

.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
    min-width: 270px !important;
    width: 270px !important;
    max-width: 270px !important;
} */

 



}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart{
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background-color: #FFF !important;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 132px;
    display: inline-flex;
    bottom: 1px;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium ul {

    padding: 0;
}


/* @media (max-width: 1450px) {

<<<<<<< HEAD
    .reports-table thead.MuiTableHead-root{
        position: sticky;
        top: 0;
    }
}
=======

} */
>>>>>>> 762f56ef7125d73db6d39aaa8b1f77b986b868dc

@media (min-width: 1250px) {


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        min-width: 140px !important;
        width: 140px !important;
        max-width: 140px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 100px !important;
        width:100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 120px !important;
        width:120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 160px !important;
        width:160px !important;
        max-width: 160px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(7) {
        min-width: 110px !important;
        width:110px !important;
        max-width: 110px !important;
    }
  


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 200px !important;
        width: 200px !important;
        max-width:200px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 100px !important;
        width:100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 120px !important;
        width:120px !important;
        max-width: 120px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 160px !important;
        width:160px !important;
        max-width: 160px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7){
        min-width: 110px !important;
        width:110px !important;
        max-width: 110px !important;
    }




    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(1){
        min-width: 200px !important;
        width: 200px !important;
        max-width:200px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(5){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(7){
        min-width: 80px !important;
        width: 80px !important;
        max-width:80px !important;
    }



    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1){
        min-width: 200px !important;
        width: 200px !important;
        max-width:200px !important;
    }
    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }
    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }
    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7){
        min-width: 80px !important;
        width: 80px !important;
        max-width:80px !important;
    }


    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }


    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 160px !important;
        width: 160px !important;
        max-width:160px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 160px !important;
        width: 160px !important;
        max-width: 160px !important;
    }

    
    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(5){
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }




    .lead-table.followup-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }

    .lead-table.followup-table .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }

    .lead-table.followup-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.followup-table .MuiDataGrid-columnHeader:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }

}




@media (min-width: 1300px) {

    .lead-table.archived-table .MuiDataGrid-columnHeader:nth-child(1) {
        min-width: 240px !important;
        width: 240px !important;
        max-width:240px !important;
    }

    .lead-table.archived-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 150px !important;
        width: 150px !important;
        max-width:150px !important;
    }
    .lead-table.archived-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 150px !important;
        width: 150px !important;
        max-width:150px !important;
    }
    .lead-table.archived-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }




    .lead-table.archived-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1){
        min-width: 240px !important;
        width: 240px !important;
        max-width:240px !important;
    }
    
    .lead-table.archived-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 150px !important;
        width: 150px !important;
        max-width:150px !important;
    }

    .lead-table.archived-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 150px !important;
        width: 150px !important;
        max-width:150px !important;
    }
    .lead-table.archived-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }




    .lead-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 240px !important;
        width: 240px !important;
        max-width:240px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 120px !important;
        width:120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 120px !important;
        width:120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 160px !important;
        width:160px !important;
        max-width: 160px !important;
    }
  


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 240px !important;
        width: 240px !important;
        max-width:240px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 120px !important;
        width:120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 120px !important;
        width:120px !important;
        max-width: 120px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 160px !important;
        width:160px !important;
        max-width: 160px !important;
    }




    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(1){
        min-width: 250px !important;
        width: 250px !important;
        max-width:250px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(7){
        min-width: 80px !important;
        width: 80px !important;
        max-width:80px !important;
    }



    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1){
        min-width: 250px !important;
        width: 250px !important;
        max-width:250px !important;
    }
    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }
    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }

    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }


    

    .lead-table.recyclebin-table .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .lead-table.recyclebin-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .lead-table.recyclebin-table .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .lead-table.recyclebin-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    

    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width:180px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }


    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 200px !important;
        width: 200px !important;
        max-width:200px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }


}



@media (min-width: 1700px) {

    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(1){
        min-width: 350px !important;
        width: 350px !important;
        max-width:350px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 200px !important;
        width: 200px !important;
        max-width:200px !important;
    }
    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-columnHeader:nth-child(7){
        min-width: 80px !important;
        width: 80px !important;
        max-width:80px !important;
    }


    .lead-table.archived-table .MuiDataGrid-columnHeader:nth-child(1) {
        min-width: 400px !important;
        width: 400px !important;
        max-width:400px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 400px !important;
        width: 400px !important;
        max-width:400px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
  


    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1){
        min-width: 350px !important;
        width: 350px !important;
        max-width:350px !important;
    }
    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 200px !important;
        width: 200px !important;
        max-width:200px !important;
    }

    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 120px !important;
        width: 120px !important;
        max-width:120px !important;
    }

    .lead-table.labelleads .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7){
        min-width: 100px !important;
        width: 100px !important;
        max-width:100px !important;
    }



    .lead-table.archived-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1){
        min-width: 400px !important;
        width: 400px !important;
        max-width:400px !important;
    }


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 400px !important;
        width: 400px !important;
        max-width:400px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }





    .lead-table.recyclebin-table .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 350px !important;
        width: 350px !important;
        max-width: 350px !important;
    }

    .lead-table.recyclebin-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 350px !important;
        width: 350px !important;
        max-width: 350px !important;
    }

    .lead-table.recyclebin-table .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 250px !important;
        width: 250px !important;
        max-width: 250px !important;
    }

    .lead-table.recyclebin-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 250px !important;
        width: 250px !important;
        max-width: 250px !important;
    }



    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 300px !important;
        width: 300px !important;
        max-width:300px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(2){
        min-width: 300px !important;
        width: 300px !important;
        max-width: 300px !important;
    }

    
    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 250px !important;
        width: 250px !important;
        max-width:250px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(4){
        min-width: 250px !important;
        width: 250px !important;
        max-width: 250px !important;
    }

 
    .lead-table.sitvisit-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
        min-width: 150px !important;
        width: 150px !important;
        max-width:150px !important;
    }

    .lead-table.sitvisit-table .MuiDataGrid-columnHeader:nth-child(6){
        min-width: 150px !important;
        width: 150px !important;
        max-width: 150px !important;
    }



}

@media (max-width: 1450px) {


    .left-menu .MuiDrawer-paperAnchorLeft {
        width: 220px;
    }
    .page-cntr {
        margin: 70px 0 0 215px;
        padding: 25px;
    }

    .css-1mf8t60-MuiListItem-root {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .fixe-menu {
        position: fixed;
        top: 0;
        left: 235px;
        width: calc(100% - 235px);
        background: #FFFFFF;
        z-index: 1;
    }

    .lead-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 170px !important;
        width: 170px !important;
        max-width: 170px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        min-width: 170px !important;
        width: 170px !important;
        max-width: 170px !important;
    }

    .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(6) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(7) {
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
        min-width: 110px !important;
        width: 110px !important;
        max-width: 110px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(8) {
        min-width: 110px !important;
        width: 110px !important;
        max-width: 110px !important;
    }

    /* .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiAvatarGroup-avatar {
        width: 25px;
        height: 25px;
    } */
/* 

    .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor{
        min-width: 120px !important;
        max-width: 120px !important;
        width: 120px !important;
    } */
    .status-cntr .MuiSelect-select.MuiSelect-outlined {
        width: 70px;
        font-size: 12px;
        padding: 7px 8px !important;
    }

    button.lead-submenu-btn {
        position: absolute;
        right: 156px;
        top: 81px;
    }
    .lead-page-cntr .create-btn2 { 
        font-size: 14px; 
        cursor: pointer
    }

    .lead-page-cntr .MuiDataGrid-toolbarContainer {
        right: 120px; 
    }

    .not-cntr button.user-cntr { 
        font-size: 14px; 
    }
    .left-menu-cntr .nav-list-cntr p { 
        font-size: 14px; 
    }
    .set-menu li a { 
        font-size: 14px; 
    }

    .dsh-se-title h1 { 
        font-size: 24px; 
    }
    .dash-led-cntr-ttl h5 { 
        font-size: 14px; 
    }

    .dash-led-cntr h3 { 
        font-size: 24px; 
    }
    .dash-led-cntr-act a { 
        font-size: 14px; 
    }
    .dash-led-cntr-act svg { 
        padding: 10px;
        width: 40px;
        height: 40px;
    }
    .dsh-se-title-filt a { 
        font-size: 14px; 
    }
    .dsh-se-title-filt .MuiInputBase-formControl {
        font-size: 14px; 
    }
    .dsh-se-title-filt label { 
        font-size: 14px; 
    }
    .graph-cntr img {
        height: 380px; 
    }
    .auth-drop li { 
        font-size: 15px; 
    }
    .auth-drop  { 
        width: 230px; 
    
        padding: 15px !important;
    }
    .auth-drop  button { 
        font-size: 14px; 
        padding: 10px 15px;
    }
    .left-menu-cntr h3 { 
        font-size: 12px; 
    }
    .lead-table p { 
        font-size: 13px; 
    }

    .lead-table .css-sb2nzj-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        font-size: 14px; 
    }
    .sec-hd h4 { 
        font-size: 24px; 
    }


    /**** Lead table *****/
/* 
    .lead-table .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }

    .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }

    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 135px !important;
        width: 135px !important;
        max-width: 135px !important;
    }

    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 135px !important;
        width: 135px !important;
        max-width: 135px !important;
    } */





  
    .left-menu-cntr {
        padding: 5px;
    }
    .fixe-menu .MuiToolbar-regular{
        min-height: 65px;
    }


    .fixe-menu-details{
        width: 100%;
        position: fixed;
         top: 0;
        z-index: 1;
    }
 
    .lead-table .MuiTablePagination-displayedRows { 
        font-size: 14px; 
    }
    .status-cntr .MuiSelect-select.MuiSelect-outlined { 
        width: auto; 
        font-size: 12px; 
    }
    .left-menu-cntr .nav-list-cntr .MuiList-root p { 
        font-size: 12px; 
    }

    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }
    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }

    .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 170px !important;
        width: 170px !important;
        max-width: 170px !important;
    }

    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        min-width: 170px !important;
        width: 170px !important;
        max-width: 170px !important;
    }


    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .recy-page-cntr  .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }

    .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(2) {
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }
    .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }


    .pop-det-text .status-cntr .MuiSelect-select.MuiSelect-outlined { 
    font-size: 12px !important; 
}
.pop-det-cntr h2 { 
    font-size: 14px !important; 
}
.pop-det-cntr p { 
    font-size: 13px !important; 
}

.pop-det-text { 
    padding: 10px 0;
}
.form-title { 
    font-size: 24px;
}
.form-title svg{ 
    width: 24px;
    height: 24px;
}

.recy-status { 
    font-size: 14px; 
}

.lead-table button { 
    font-size: 14px; 
}

/* 
.closed-page-cntr .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 260px !important;
    width: 260px !important;
    max-width: 260px !important;
}
.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 260px !important;
    width: 260px !important;
    max-width: 260px !important;
}

.closed-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 260px !important;
    width: 260px !important;
    max-width: 260px !important;
}
.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 260px !important;
    width: 260px !important;
    max-width: 260px !important;
} */




}
@media (max-width: 1366px) {

    .dash-led-cntr-ttl h5 {
        font-size: 12px;
    }
    .dash-led-cntr-ttl span { 
        font-size: 14px; 
    }
/** lead table ***/
/* .lead-table .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
    .lead-table .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 155px !important;
        width: 155px !important;
        max-width: 155px !important;
    }


    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
        min-width: 120px !important;
        width: 120px !important;
        max-width: 120px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
        min-width: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
    .lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
        min-width: 155px !important;
        width: 155px !important;
        max-width: 155px !important;
    } */


/** recy table ***/
/* .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 180px !important;
    width: 180px !important;
    max-width: 180px !important;
} */

    /* .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    } */



/* 
    .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 150px !important;
        width: 150px !important;
        max-width: 150px !important;
    }

    .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        min-width: 150px !important;
        width: 150px !important;
        max-width: 150px !important;
    }

    .recy-page-cntr .MuiDataGrid-columnHeader:nth-child(5) {
        min-width: 180px !important;
        width: 180px !important;
        max-width: 180px !important;
    }
    
        .recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
            min-width: 180px !important;
            width: 180px !important;
            max-width: 180px !important;
        } */


    
    .graph-cntr img {
        height: 350px;
    }
    .lead-table .MuiDataGrid-virtualScroller {
        overflow: hidden !important;
    }

    /* .closed-page-cntr .MuiDataGrid-columnHeader:nth-child(3) {
        min-width: 220px !important;
        width: 220px !important;
        max-width: 220px !important;
    }

    .closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        min-width: 220px !important;
        width: 220px !important;
        max-width: 220px !important;
    }

   
    .closed-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    }

    .closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        min-width: 200px !important;
        width: 200px !important;
        max-width: 200px !important;
    } */


    

    .right-form label { 
        font-size: 12px; 
    }

    .right-form .MuiGrid-item{
        padding-top: 15px;
        margin-top: 0;
    }


    .lead-page-cntr .MuiDataGrid-toolbarContainer {
 
        top: -81px;
    }
    .right-form .main-btn2 { 
        font-size: 14px; 
        padding: 10px 30px; 
    }

}




@media (max-width: 1280px) {

/* 
.lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
.lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}

.lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}

.lead-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    min-width: 130px !important;
    width: 130px !important;
    max-width: 130px !important;
} */
/* .lead-table button { 
    width: 100%;
    display: block;
} */
/* 
.recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 150px !important;
    width: 150px !important;
    max-width: 150px !important;
}

.recy-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 130px !important;
    width: 130px !important;
    max-width: 130px !important;
}
.recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
.recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    min-width: 155px !important;
    width: 155px !important;
    max-width: 155px !important;
}

.lead-table .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
.lead-table .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
.lead-table .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}

.recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    min-width: 180px !important;
    width: 180px !important;
    max-width: 180px !important;
}
.recy-page-cntr .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 180px !important;
    width: 180px !important;
    max-width: 180px !important;
}

.recy-page-cntr .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 150px !important;
    width: 150px !important;
    max-width: 150px !important;
}
.recy-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    min-width: 155px !important;
    width: 155px !important;
    max-width: 155px !important;
}
.recy-page-cntr .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 155px !important;
    width: 155px !important;
    max-width: 155px !important;
} */
.lead-table .MuiDataGrid-virtualScroller {
    overflow: auto !important;
}

/* 
.closed-page-cntr .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
}

.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
}


.closed-page-cntr .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
}

.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
}


.closed-page-cntr .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
}

.closed-page-cntr .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
} */


}


/* Status Colours*/

.newenq .MuiSelect-select.MuiSelect-outlined {
    background-color:#4361EE ;
    color: #FFFFFF; 
    -webkit-text-fill-color: unset;
  }             
  
  .vrfy .MuiSelect-select.MuiSelect-outlined {
    background-color: #CBB2FE ; 
    color: #FFFFFF;
    -webkit-text-fill-color: unset;
  }
  
  .nego .MuiSelect-select.MuiSelect-outlined {
    background-color: #023047 ; 
    color: #FFFFFF;
    -webkit-text-fill-color: unset;
  }
  
  .conf .MuiSelect-select.MuiSelect-outlined {
    background-color: #0EBD54; 
    color: #FFFFFF;
    -webkit-text-fill-color: unset;
  }
  
  .inva .MuiSelect-select.MuiSelect-outlined {
    background-color: #FFBC42; 
    color: #FFFFFF;
    -webkit-text-fill-color: unset;
  }
  
  .futur .MuiSelect-select.MuiSelect-outlined{
    background-color: #00B4D8 ; 
    color: #FFFFFF;
    -webkit-text-fill-color: unset;
    
  }
  
  .los .MuiSelect-select.MuiSelect-outlined {
    background-color: #EE6055 ; 
    color: #FFFFFF;

    -webkit-text-fill-color: unset;
    
  }
  

  #import-lead {
    border-radius: 9px;
    border: 1px solid var(--stroke, #E2E8F0);
    background: #FFF;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);
    color: #64748B !important;
    font-size: 0px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 12px;
    min-width: 52px;
    width: unset;
    position: absolute;
    right: 256px;
    display: inline-flex;
    bottom: 1px;
}
/** css end **/ 


.talk-bubble {
    display: inline-block;
    position: relative;
    width:90%;
    height: auto;
    background-color: #F3F3FF;
    border: 1px solid #5046e4;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    padding: 10px 20px 0;
    margin-top: 20px;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
    left: auto;
    right: -35px;
    top: -1px;
	bottom: auto;
	border: 35px solid;
	border-color: #5046e4 transparent transparent transparent;
}
.tri-right.right-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -33px;
    top: 0px;
    bottom: auto;
    border: 38px solid;
    border-color: #F3F3FF transparent transparent transparent;
}

.talktext h4 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
    font-size: 14px;
}


.talktext h5{
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 0;
    font-weight: 500;
}


.talk-bubble-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .talk-bubble:nth-child(even) {
    margin: auto;
    margin-right: 40px;
} */
.talktext p {
    font-size: 12.5px;
    margin: 5px 0;
}

.author {
    display: flex;
   justify-content: space-between;
   margin-top: 15px;
}


.details-page-block-content {
    display: flex;
    justify-content: space-between;
    padding: 29px;
}
.detail-page-left-block {
    max-width: 48%;
    flex-basis: 48%;
    border: 1px solid #cfcfcf;
}
.detail-page-right-block {
    max-width: 48%;
    flex-basis: 48%;
}
.detail-page-left-block .pop-det-text, .detail-page-right-block .pop-det-text {
    padding: 10px 15px;
}
.detail-page-right-block-inner {
    border: 1px solid #cfcfcf;
    margin-bottom: 20px;
}

.details-page-block-content .pop-det-text h2 {
    font-size: 15px !important;
}
.add-note-block {
    flex-direction: column !important;
    align-items: end !important;
    gap: 20px;
    padding: 0 !important;
}

.add-note-block textarea{
    width: 100% !important;
}
.add-note-block-textarea{
    width: 100% !important;
}

.note-result-block {
    margin-top: 10px;
}

.note-result-block tr {
    margin-top: 20px !important;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    padding: 10px;
}

.note-result-block tr td {
    padding: 0;
    border-bottom: 0px !important;
}
.note-content {
    width: 68%;
}

.go-back-btn{  
      margin-left: 15px;
    margin-top: 16px;
}

.source-list.extradata .pop-det-text {
    flex-direction: row !important;
    width: 100%;
}
.pop-det-cntr.source.extradata {
    margin-bottom: 20px;
    border: 1px solid #cfcfcf;
}
.pop-det-text h2 {
    font-weight: bolder !important;
    color: #000;
}
.pop-det-cntr.source {
    border: 1px solid #ededed;
    border-radius: 5px;
}

.pop-det-cntr.source.sourse-list{
    padding: 10px 15px !important
}

.pop-det-text:last-child{
    border-bottom: 0px !important;
}
.pop-det-cntr.add-site-visit {
    border: 1px solid #cfcfcf;
}
.pop-det-text.source-list {
    padding: 10px 0 !important;
}


@media (max-width: 1300px) {
    .lead-table .MuiDataGrid-columnHeaderTitle {
        font-size: 13px;
    }

/* 
    .home-lead-chart-big {
        width: 48%;
    }
    .home-lead-chart-right {
        width: 48%;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
    }
    .home-lead-chart-full-item {
        width: 100%;
        background: #fdf7e5;
        padding: 30px 30px;
        border-radius: 10px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
    } */


}


@media (max-width: 1250px) {
    .css-yrdy0g-MuiDataGrid-columnHeaderRow {
        display: flex;
        overflow: scroll;
        width: 100%;
    }
    .lead-table .MuiDataGrid-virtualScrollerRenderZone {
        position: relative;
        overflow: scroll;
        width: 100%;
    }
    .lead-table .MuiDataGrid-virtualScroller {
        overflow: scroll !important;
        width: 100%;
    }
    .MuiDataGrid-row {
        overflow: scroll;
        width: 100%;
    }

    .css-auylq4-MuiGrid-root {
        -webkit-flex-basis: 50% !important;
        -ms-flex-preferred-size: 50% !important;
        flex-basis: 50% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 50% !important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-4.css-kgs8gf-MuiGrid-root{
        -webkit-flex-basis: 50% !important;
        -ms-flex-preferred-size: 50% !important;
        flex-basis: 50% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 50% !important;
    }

}


@media (max-width: 991px) {
    .lead-table .MuiDataGrid-columnHeaderTitle {
        font-size: 13px;
    }
    .css-4nuj1i {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        padding: 10px;
    }
    .left-menu-cntr .nav-list-cntr p {
        font-size: 13px;
        line-height: 16px;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
        width: 16px;
        height: 16px;
    }
    .left-menu-cntr ul li .MuiListItemIcon-root {
        min-width: 22px;
        font-weight: 500;
    }
    li.MuiListItem-root.MuiListItem-gutters {
        padding-top: 6px;
        padding-bottom: 6px;
    }


    .left-menu .MuiDrawer-paperAnchorLeft {
        width: 160px;
    }
    .css-auylq4-MuiGrid-root {
        -webkit-flex-basis: 50% !important;
        -ms-flex-preferred-size: 50% !important;
        flex-basis: 50% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 50% !important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-4.css-kgs8gf-MuiGrid-root{
        -webkit-flex-basis: 50% !important;
        -ms-flex-preferred-size: 50% !important;
        flex-basis: 50% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 50% !important;
    }
    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeLarge.css-giqa97-MuiButtonBase-root-MuiIconButton-root {
        display: none;
    }


    button.user-cntr span {
        display: none;
    }
    .css-hyum1k-MuiToolbar-root {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .fixe-menu {
        position: fixed;
        top: 0;
        left: 158px;
        width: calc(100% - 158px);
        background: #FFFFFF;
        z-index: 1;
    }
    .page-cntr {
        margin: 70px 0 0 160px;
        padding: 15px;
    }
    .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.css-1a3p9hh-MuiAvatar-root {
        margin-right: 0 !important;
    }
}


@media (max-width: 767px) {

    .detail-page-left-block {
        max-width: 100%;
        flex-basis: 100%;
        border: 0px solid #cfcfcf;
    }
    .detail-page-right-block {
        max-width: 100%;
        flex-basis: 100%;
    }
    .details-page-block-content {
        flex-direction: column;
        padding: 10px;
    }
    .details-page-block-content .pop-det-text h2 {
        font-size: 14px !important;
    }
    .pop-det-cntr.source.sourse-list {
        padding: 10px 0px !important;
    }
    .pop-det-text.source-list {
        padding: 10px 15px !important;
    }
    .pop-det-cntr {
        padding: 0px;
    }
}
#searchby-leads{
    
    background: white;
    height: 28px;
    padding: 10px 25px;
    width: 217px;
    border: 0px;
    box-shadow: 2.000000238418579px 4.000000476837158px 10.000000953674316px 0px rgba(180, 191, 205, 0.20);

}
